import React from "react";
import { isMobile } from 'react-device-detect';

function Benefits() {
  return (
    <div className="benefits row m-0 p-0" id="Benefits">
      <div id="title-mobile" className="mb-3">Benefits for you</div>
      <div className="col-md-6 p-4 d-flex flex-column text-center text-md-end">
        <h3>Become</h3>
        <h3 id="benefitsPercentage">100%</h3>
        <h3>Stress Free and Focus on Wellness </h3>
      </div>
      <div className="col-md-6 p-4 d-flex flex-column ">
        <h6 id="title-desktop">Benefits for you</h6>
        <h6
          className="lh-base"
          id="benefits-desc"
        >
          With home chef prepping meals for you, you not only save on costs and get fresh home cooked meals cooked per your preference in taste, but you also save a lot more time on meal prepping/planning, cooking, cleaning dishes and kitchen area. You can now use your valuable time to spend with your friends and family and live a stress free life.
        </h6>
      </div>
    </div >
  );
}

export default Benefits;
{/* <div className="container-fluid" >
  <div className="row m-5" id="Benefits">
    <div className="col-md-6">
      <h2 style={{ fontSize: "2rem" }}>Become</h2>
      <h1 id="benefitsPercentage">100%</h1>
      <h4 style={{ fontSize: "2.5rem" }} className="my-0">Stress Free and</h4>
      <p style={{ fontSize: "2.5rem" }} className="my-0">Focus on Wellness</p>
    </div>
    <div className="col-md-6">
      <h2>Benefits for you</h2>
      <h4 className="why-pick">Why Pick Chef Republic?</h4>
      <ul style={{ listStyle: "none" }} className="mt-5">
        <li className="my-3" style={{ fontSize: "0.92rem" }}>
          ⭐ Enjoy more free time and less stress—let us handle the meal prep!
        </li>
        <li className="my-3" style={{ fontSize: "0.92rem" }}>
          ⭐ Get meals that match your tastes and dietary needs.
        </li>
        <li className="my-3" style={{ fontSize: "0.92rem" }}>
          ⭐ Save money and time by letting us take care of everything.
        </li>
        <li className="my-3" style={{ fontSize: "0.92rem" }}>
          ⭐ We use only the fresh ingredients in every meal.
        </li>
        <li className="my-3" style={{ fontSize: "0.92rem" }}>
          ⭐ We clean up the kitchen, so you don’t have to.
        </li>
      </ul>
    </div>
  </div>
</div> */}