import React, { useEffect } from "react";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import fourthBlog from "../images/fourthBlog.jpeg"

function FourthBlog(props) {
  useEffect(() => {
    const title = "HOME | CHEF REPUBLIC";
    const desc =
      "On Demand Home Chef Service, for Personalized Meal Prep at an affordable rate. Book a home chef to come to your home with fresh groceries and cook/meal prep healthy homemade meals for you and your family for up to 20 servings, catered to your preference and taste.";

    document.title = title;

    const metaTitle = document.querySelector("meta[name='title']");
    const metaDescription = document.querySelector("meta[name='description']");
    const metaTitleOG = document.querySelector("meta[property='og:title']");
    const metaDescriptionOG = document.querySelector(
      "meta[property='og:description']"
    );
    const metaTitleTwitter = document.querySelector(
      "meta[property='twitter:title']"
    );
    const metaDescriptionTwitter = document.querySelector(
      "meta[property='twitter:description']"
    );

    if (metaTitle) metaTitle.setAttribute("content", title);
    if (metaDescription) metaDescription.setAttribute("content", desc);
    if (metaTitleOG) metaTitleOG.setAttribute("content", title);
    if (metaDescriptionOG) metaDescriptionOG.setAttribute("content", desc);
    if (metaTitleTwitter) metaTitleTwitter.setAttribute("content", title);
    if (metaDescriptionTwitter)
      metaDescriptionTwitter.setAttribute("content", desc);
  }, []);

  return (
    <>
      <Nav customer={props.customer} home={true} />

      <div className="container">
        <p className="fw-bold mt-4" style={{ fontSize: "2rem" }}>
        Where to Hire a Chef for Your House Party?

        </p>
        <div
          id="firstBlogs"
          style={{
            background: `url(${fourthBlog})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        ></div>

        <p className="mt-3">
        If you're planning a house party and want a stress-free dining experience, Chef Republic’s on-demand home chef service could be the perfect solution. Recently, a host used their service and found it a game-changer. They explored a range of chef profiles and customized menus to find the ideal match for their event.
        </p>
        <p>
          {" "}
          The chef arrived with fresh ingredients, prepared a selection of homemade meals, and even managed the cleanup, leaving the host free to enjoy the party with their guests. The entire process was seamless, allowing them to focus on the event rather than worry about cooking or post-party mess.

        </p>
     
        <p>
        With fixed-price meal options, budgeting was straightforward, and the quality of food remained top-notch. If you're looking to host a party without the hassle of meal prep, an on-demand personal chef service like Chef Republic is highly recommended for a smooth and enjoyable experience.

        </p>
       

       
      </div>

      <Footer customer={props.customer} home={true} />
    </>
  );
}

export default FourthBlog;