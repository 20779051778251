


import React, {useEffect, useState} from 'react';
import "../CSS File/SubscriptionSummary.css";
import chatBubble from "../images/icons/chatBubble.png";
import smile from "../images/smile.svg"
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { IoMdStopwatch } from "react-icons/io";
import { MdOutlineAttachMoney } from "react-icons/md";
import { TfiTimer } from "react-icons/tfi";
import profile from '../images/Vector.jpg';

import DynamicDialog from './DynamicDialog';


const SubscriptionSummary = (props) => {


  const [content, setdPlans] = useState([]);
    const [PlanList, setPlans] = useState([]);
    const [PLanDetails,SetPlanData]=useState([]);
    const [planName,SetplanName]=useState("");
    const [openaddDialog, setOpenaddDialog] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [imageDialog, setimageDialog] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [isApiSuccess, setIsApiSuccess] = useState(false); // Track if API call succeeded
    const [loading, setLoading] = useState(true);

    
    const handleButtonClick = (Option ,chefOption) => {


if (chefOption.plan_id === '1') {
  console.log(Option.length);
  console.log(chefOption.subscribe_chef_id);
  EnterOrderDetail(chefOption.subscribe_chef_id);
} else {
  console.log(Option.length);

  if (Option.length < 2) {
    console.log("not route Chef");
  } else {
    console.log("route Chef");
    EnterOrderDetail(chefOption.subscribe_chef_id);
  }
}
    };

    const AddSubscription = () => {
      navigate("/subscription-plans")

      // if(!PLanDetails.chef_status && PLanDetails.plan_status){
      //   navigate("/chef-list")
      // }else{
      //   navigate("/subscription-plans")
      // }
    
    }

  let navigate = useNavigate();

    useEffect(() => {
      routeTosignIn();
      checksubcription();
      SubscriptionPlan().finally(() => {
        setLoading(false); // Stop loading regardless of success or failure
      });
    
    }, [planName]);
    const checksubcription = async () => {
      try {
        let response = await fetch(
          `${process.env.REACT_APP_BASE_URL}subscription/check_current_plan`,
          {
            method: "POST",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              user_id: `${localStorage.getItem("logged_user_id")}`,
            }),
          }
        );
        const res_json = await response.text();
        const CurrentPlan = JSON.parse(res_json);

        if (CurrentPlan.status === true) {
          console.log(CurrentPlan);
          SetPlanData(CurrentPlan);
          setIsApiSuccess(true);
        } else {
          setIsApiSuccess(false); // Handle error
          console.error("Error: Response status is false");
        }
      } catch (error) {
        console.error("Error while fetching data, please try later:", error);
      }
    };
    let SubscriptionPlan = async () => {
      try {
        let response = await fetch(
          `${process.env.REACT_APP_BASE_URL}subscription/fetch_subscription_chef`,
          {
            method: "POST",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              user_id: `${localStorage.getItem("logged_user_id")}`,
            }),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        let responseData = await response.json();
        console.log(responseData);
        if (responseData) {
          console.log(responseData);
          setPlans(responseData);
          // navigate("/subscription-summary");
          setIsApiSuccess(true);
        } else {
          setIsApiSuccess(false); 
          console.error("Error: Response status is false");
        }
      } 
      catch (error) {
        console.error("Error fetching subscription data:", error);
      }
     
     
    };
    let cancelSubscription = async () => {
      try {
        let response = await fetch(
          `${process.env.REACT_APP_BASE_URL}subscription/cancel_subscription_plan`,
          {
            method: "POST",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              user_id: `${localStorage.getItem("logged_user_id")}`,
            }),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const res_json = await response.text();
        const data = JSON.parse(res_json);

        if (data.status === true) {
          localStorage.removeItem("subscription_id");

          SetplanName(data.messages);
        } else {
          console.error("Error while fetching data, please try later");
        }
      } catch (error) {
        console.error("Error occurred:", error);
      }
    };
    let cancelConfirmationSubscription = async () => {
      setOpenDialog(!openDialog);
    };

    const changeScreen = () => {
      cancelSubscription();
      setOpenDialog(false);
      SetplanName("");
      setimageDialog(true);
    };
    const changeScreenSecondButton = () => {
      navigate("/subscription-plans");
    };

    const handleNavigate = () => {
      navigate("/chef-list");
    };

    const EnterOrderDetail = (chef_id) => {
      localStorage.setItem("chef_id", chef_id);
      navigate("/booking-detail");
    };

    const routeTosignIn = async () => {
      if((localStorage.getItem("role"))){
        console.log('display Play List');
      }else{
        navigate("/sign-in");
      }
    };
  
    return (
      <>
        <DynamicDialog
          openDialog={openaddDialog}
          title={
            !PLanDetails.chef_status && PLanDetails.plan_status
              ? `${PLanDetails.plan_name} \nYour Chef is not added. Please add a chef`
              : "Do you want to view and add a new subscription plan?"
          }
          buttonText={"Yes"}
          showSecondButton={false}
          changeScreen={() => AddSubscription()}
          closeDialog={(value) => {
            setOpenaddDialog(value);
          }}
        />

        {imageDialog ? (
          <DynamicDialog
            getDialog={1}
            imagesrc={smile}
            openDialog={!openDialog}
            title={planName}
            // Your Subscription Plan Has Been Canceled
            buttonText={"Cancel Subscription"}
            showSecondButton={true}
            changeScreen={() => changeScreen()}
            secondButtonText={"Select Another Plan"}
            changeScreenSecondButton={() => changeScreenSecondButton()}
            closeDialog={(value) => {
              setimageDialog(value);
            }}
          />
        ) : (
          <DynamicDialog
            getDialog={2}
            imagesrc={""}
            openDialog={openDialog}
            title={"Do you want to cancel subscription?"}
            buttonText={"Yes"}
            showSecondButton={true}
            changeScreen={() => changeScreen()}
            secondButtonText={"No"}
            changeScreenSecondButton={() => setOpenDialog(!openDialog)}
            closeDialog={(value) => {
              setOpenDialog(value);
            }}
          />
        )}

        {
          <div className="container">
            <p className="mt-2 mb-0 text-center">Subscription Summary</p>

            {loading ? (
      <div className="text-center mt-5">
        <p>Loading your subscriptions...</p> {/* Show a loading message */}
      </div>
    ) : PlanList.length > 0 ? (
      <>
        <div className="mobile new-design-card mt-3">
      <div
        style={{ display: !PLanDetails.chef_status ? "block" : "none" }}
      >
        <div
          style={{
            paddingTop: "5px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <p
            role="button"
            style={{
              whiteSpace: "pre-wrap",
              textAlign: "center",
              marginTop: "7px",
              marginBottom: "3px",
              marginRight: "6px",
              color: "#ff5e41",
              cursor: "pointer",
              fontSize: "0.9rem",
            }}
            onClick={() => AddSubscription()}
          >
            + Add Subscription
          </p>
        </div>
      </div>
      <div className="row">
        {PlanList.map((option, index) => (
          <div className="col-md-4 col-sm-12" key={index}>
            <div
              className="border row border-1 m-1 py-2"
              style={{
                borderRadius: "20px",
                boxShadow: "0 0 0 rgba(0,0,0,0.2)", // Corrected boxShadow syntax
              }}
            >
              <div className="row">
                <div>
                  <span
                    className="badge text-wrap"
                    style={{
                      borderRadius: "20px",
                      color: "white",
                      backgroundColor:
                        option.status === "Active" ? "#4dff4d" : "red", // Simplified the static backgroundColor
                    }}
                  >
                    {option.status}
                  </span>

                  <span
                    className="badge text-wrap"
                    style={{
                      borderRadius: "20px",
                      marginLeft: "10px",
                      color: "white",
                      backgroundColor: `${option.color_code}`,
                    }}
                  >
                    {option.plan_name}
                  </span>
                </div>

                <div className="row my-2">
                  <p className="pl-4 mb-0">
                    Subscription ID: {option.subscription_no}
                  </p>
                </div>
                {option.chef.map((chefData, ChefIndex) => (
                  <div className="chef-new-card" key={ChefIndex}>
                    <div className="row">
                      <div className="col-md-2 col-2">
                        <div
                          className="d-flex justify-content-center align-items-center my-2"
                          style={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "50%",
                          }}
                        >
                          <img
                            src={
                              chefData.booking_by_user_profile_image
                                ? chefData.booking_by_user_profile_image
                                : profile
                            }
                            style={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%",
                            }}
                            alt="Profile"
                          />
                        </div>
                      </div>
                      <div className="col-md-10 col-10 d-flex align-items-center">
                        <h6 className="fw-bold">
                          {chefData.chef_name}
                        </h6>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-2 col-2">
                        <button
                          style={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "50%",
                            border: "1px solid #FF5E41",
                            cursor: "pointer",
                            backgroundColor: "#FF5E41",
                          }}
                          className="px-1"
                          onClick={() => {
                            console.log(option.chef.length);
                            navigate("/subscription-message", {
                              state: {
                                data: {
                                  plan_id:option.plan_id,
                                  plan_name:option.plan_name,
                                   color_code:option.color_code,
                                  image: chefData.profile_image,
                                  name: chefData.chef_name,
                                  subscription_status: chefData.status,
                                  customer_id: chefData.user_id,
                                  chef_id: chefData.subscribe_chef_id,
                                },
                              },
                            });
                          }}
                        >
                          <img
                            src={chatBubble}
                            className="img-fluid"
                            alt=""
                          />
                        </button>
                      </div>
                      <div className="col-md-10 col-10">
                        <div className="row my-2">
                          <div className="col-1">
                            <p className="p-0 mb-0">
                              <IoMdStopwatch
                                style={{
                                  fontSize: "1.2rem",
                                }}
                              />
                            </p>
                          </div>
                          <div className="col-10">
                            <p
                              className="p-0 m-0"
                              style={{ fontSize: "0.9rem" }}
                            >
                              Every {chefData.timeslot}
                            </p>
                          </div>
                        </div>
                        <div className="row my-2">
                          <div className="col-1">
                            <p className="p-0 mb-0">
                              <MdOutlineAttachMoney
                                style={{ fontSize: "1.2rem" }}
                              />
                            </p>
                          </div>
                          <div className="col-10 text-break">
                            <p
                              className="mb-0"
                              style={{
                                fontSize: "0.9rem",
                                paddingTop: "0.1rem",
                              }}
                            >
                              {chefData.price}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="button-container">
                      <div style={{ position: "relative" }}>
                        <button
                          className="px-1 container-button"
                          style={{ width: "140px", fontSize: "0.7rem" }}
                          onClick={() => {
                            localStorage.setItem(
                              "subscription_id",
                              chefData.subscription_id
                            );
                            handleButtonClick(option.chef, chefData);
                          }}
                          disabled={
                            option.status === "Inactive" ||
                            option.min_dates > option.chef.length
                          }
                        >
                          Enter Order Details
                        </button>
                      </div>

                      <button
                        className="mx-1 container-button"
                        style={{ width: "140px", fontSize: "0.7rem" }}
                        onClick={() => {
                          localStorage.setItem(
                            "subscription_id",
                            chefData.subscription_id
                          );
                          navigate("/chef-list", {
                            state: {
                              option: chefData.subscription_id,
                              type: "subscription_Id",
                            },
                          });
                        }}
                        disabled={
                          option.status === "Inactive" ||
                          option.min_dates > option.chef.length
                        }
                      >
                        Replace Chef
                      </button>
                    </div>
                    {ChefIndex < option.chef.length - 1 && (
                      <hr style={{ marginBottom: "5px" }} />
                    )}
                  </div>
                ))}
                {option.status === "Active" &&
                  option.max_dates > option.chef.length && (
                    <div className="select one more day text-center">

<p
            role="button"
            onClick={() => handleNavigate()}
            style={{
              whiteSpace: "pre-wrap",
              textAlign: "center",
              marginTop: "7px",
              marginBottom: "3px",
              marginRight: "6px",
              color: "#ff5e41",
              cursor: "pointer",
              fontSize: "0.7rem",
            }}
          
          >
            + Select One More Day For The Week
          </p>
                      {/* <button
                        className="px-3 container-button"
                        style={{ fontSize: "0.7rem" }}
                        onClick={() => handleNavigate()}
                      >
                        + Select One More Day For The Week
                      </button> */}
                    </div>

                    // <div
                    //   className="below_button"
                    //   style={{
                    //     backgroundColor: "#FFEEEB",
                    //     // margin: "5px",
                    //     borderRadius: "5px",
                    //     display: "flex",
                    //     justifyContent: "center",
                    //     color: "#FF5E41",
                    //     fontSize: "0.7rem",
                    //     padding: "0.5rem",
                    //     marginTop: "10px",
                    //   }}
                    // >
                    //   <div onClick={() => handleNavigate()}>
                    //     + Select One More Day For The Week
                    //   </div>
                    // </div>
                  )}
              </div>
            </div>

            <div>
              {option.status === "Active" &&
                option.min_dates > option.chef.length && (
                  <div
                    style={{
                      color: "#FF5E41",
                      marginBottom: "5px",
                      marginLeft: "10px",
                      textAlign: "center",
                    }}
                  >
                    <span style={{ fontSize: "0.55rem" }}>
                      {option.message}{" "}
                    </span>
                  </div>
                )}
            </div>

            <div
              style={{
                paddingTop: "5px",
                textAlign: "center",
                display: option.status === "Active" ? " block" : "none",
              }}
            >
              <button
                className="px-1 container-button mb-3"
                style={{ width: "150px", fontSize: "0.7rem" }}
                onClick={() => cancelConfirmationSubscription()}
                disabled={
                  option.status === "Inactive" ||
                  option.min_dates > option.chef.length
                }
              >
                Cancel Subscription
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
    <div className="App text-start">
      <div
        style={{
          display: !PLanDetails.chef_status ? "block" : "none",
        }}
      >
        <div
          style={{
            paddingTop: "5px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <button
            className="px-1 container-button"
            style={{ width: "140px", fontSize: "0.7rem" }}
            onClick={() => AddSubscription()}
          >
            Add Subscription
          </button>

       
        </div>
      </div>

      { isApiSuccess && PlanList.length > 0 && (
        PlanList.map((option, index) => (
          <div key={index} className="row my-2 ">
            <div
              className="p-3 "
              style={{
                borderRadius: "20px",
                border: "1px solid #dee2e6",
              }}
            >
              <div className="mb-3">
                <span style={{ marginRight: "20px" }}>
                  Subscription ID: {option.subscription_no}{" "}
                </span>
                <span
                  className="badge "
                  style={{
                    borderRadius: "20px",
                    color: "white",
                    backgroundColor:
                      option.status === "Active" ? "#4dff4d" : "red", // Simplified the static backgroundColor
                  }}
                >
                  {option.status}
                </span>
                <span
                  className="badge"
                  style={{
                    borderRadius: "20px",
                    marginLeft: "10px",
                    color: "white",
                    backgroundColor: `${option.color_code}`, // Simplified the static backgroundColor
                  }}
                >
                  {option.plan_name}
                </span>
              </div>

              {option.chef.map((chefOption, chefIndex) => (
                <div
                  key={chefIndex}
                  className="row me-2 my-2"
                  style={{
                    justifyContent: "start",
                    display: "flex",
                  }}
                >
                  <div
                    className="col-md-3"
                    style={{ borderRight: "2px solid black" }}
                  >
                    <div className="chef-name">
                      <img
                        src={
                          chefOption.booking_by_user_profile_image
                            ? chefOption.booking_by_user_profile_image
                            : profile
                        }
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          marginRight: "5px",
                        }}
                        alt="Profile"
                      />
                      <span>{chefOption.chef_name}</span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="chef-time mt-2">
                      <TfiTimer size={20} />
                      <span className="ms-2">
                        Every {chefOption.timeslot}{" "}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div
                      className="row chef-button"
                      style={{ borderRight: "2px solid black" }}
                    >
                      <div className="col-md-5">
                        <button
                          className="px-1 container-button"
                          style={{ width: "140px", fontSize: "0.7rem" }}
                          onClick={() => {
                            localStorage.setItem(
                              "subscription_id",
                              chefOption.subscription_id
                            );
                            handleButtonClick(option.chef, chefOption);
                          }}
                          disabled={
                            option.status === "Inactive" ||
                            option.min_dates > option.chef.length
                          }
                        >
                          Enter Order Details
                        </button>
                      </div>
                      <div className="col-md-5">
                        <button
                          className="mx-1 container-button"
                          style={{ width: "140px", fontSize: "0.7rem" }}
                          onClick={() => {
                            localStorage.setItem(
                              "subscription_id",
                              chefOption.subscription_id
                            );
                            navigate("/chef-list", {
                              state: {
                                option: chefOption.subscription_id,
                                type: "subscription_Id",
                              },
                            });
                          }}
                          disabled={
                            option.status === "Inactive" ||
                            option.min_dates > option.chef.length
                          }
                        >
                          Replace Chef
                        </button>
                      </div>
                      <div className="col-md-2">
                        <button
                          className="px-1 container-button"
                          onClick={() => {
                            debugger;
                            console.log(option.chef.length);
                            navigate("/subscription-message", {
                              state: {
                                data: {
                                  // status: option,
                                  plan_id:option.plan_id,
                                  plan_name:option.plan_name,
                                   color_code:option.color_code,
                                  image: chefOption.profile_image,
                                  name: chefOption.chef_name,
                                  subscription_status:
                                    chefOption.status,
                                  customer_id: chefOption.user_id,
                                  chef_id: chefOption.subscribe_chef_id,
                                },
                              },
                            });
                          }}
                        >
                          <img
                            src={chatBubble}
                            className="img-fluid"
                            alt=""
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div
                      className="px-1 mt-2"
                      style={{
                        backgroundColor: "#FFFFFF",
                        color: "#000000",
                      }}
                    >
                      ${option.price}
                    </div>
                  </div>
                </div>
              ))}
              <div className="below_button">
                {option.status === "Active" &&
                  option.max_dates > option.chef.length && (
                    <div className="row text-center">
                      <div className="mb-3">

                      <button
                          className="px-2 container-button"
                          style={{ fontSize: "0.7rem" }}
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                          onClick={() => handleNavigate()}
                        >
                          {isHovered ? (
                            <span
                            
                              style={{ fontSize: "0.7rem" }}
                            >
                              + Select One More Day For The Week
                            </span>
                          ) : (
                            <span
                              className="px-3"
                              style={{ fontSize: "0.8rem" }}
                            >
                              +
                            </span>
                          )}
                        </button>



                        {/* <button
                          className="p-2"
                          style={{
                            borderRadius: "20px",
                            border: "none",
                            backgroundColor: "#FF5E41",
                            color: "white",
                            fontSize: "1rem",
                            cursor: "pointer",
                          }}
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                          onClick={() => handleNavigate()}
                        >
                          {isHovered ? (
                            <span
                            
                              style={{ fontSize: "0.7rem" }}
                            >
                              + Select One More Day For The Week
                            </span>
                          ) : (
                            <span
                              className="px-3"
                              style={{ fontSize: "0.8rem" }}
                            >
                              +
                            </span>
                          )}
                        </button> */}
                      </div>
                    </div>
                  )}
              </div>
            </div>
            <div>
              {option.status === "Active" &&
                option.min_dates > option.chef.length && (
                  <div
                    style={{
                      color: "#FF5E41",
                      marginBottom: "5px",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "0.7rem",
                      }}
                    >
                      {option.message}{" "}
                    </span>
                  </div>
                )}
            </div>
            <div
              style={{
                paddingTop: "5px",
                display: option.status === "Active" ? " block" : "none",
              }}
            >
              <button
                className="px-1 container-button"
                style={{ width: "140px", fontSize: "0.7rem" }}
                onClick={() => cancelConfirmationSubscription()}
              >
                Cancel Subscription
              </button>

              {/* <p
                className=" mx-2"
                style={{
                  textWrap: "pretty",
                  textAlign: "center",
                  justifyContent: "center",
                  backgroundColor: " #ff5e41",
                  width: "200px",
                  padding: "7px 10px",
                  borderRadius: "28px",
                  color: "white",
                  cursor: "pointer",
                }}
                onClick={() => cancelConfirmationSubscription()}
              >
                Cancel Subscription
              </p> */}
            </div>
          </div>
        ))
      ) }
    </div>
      </>
    
    ) : (

<>
<div
      style={{
        paddingTop: "5px",
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      <button
        className="px-1 container-button"
        style={{ width: "140px", fontSize: "0.7rem" }}
        onClick={() => AddSubscription()}
      >
        Add Subscription
      </button>

   
    </div>
      <div className="text-center mt-5">
        <p>You Do Not Have Any Subscriptions Yet</p> {/* Show if no plans after successful API */}
      </div>
</>
    
    )}




         
          </div>
        }
      </>
    );
  }
  
  export default SubscriptionSummary;

   {/* <div className="App">
            {PlanList.length > 0 ? (
              PlanList.map((option, index) => (
                <div
                  key={index}
                  className="row d-flex justify-content-center my-2 "
                >
                  <div
                    className="left-card col-md-9 col-lg-9 col-sm-9 col-xs-9 d-flex flex-column"
                    style={{
                      backgroundColor: "#FFEEEB",
                      borderRadius: "20px",
                    }}
                  >
                    {option.chef.map((chefOption, chefIndex) => (
                      <div
                        key={chefIndex}
                        className="row  me-2 my-1 flex-fill"
                        style={{
                          textAlign: "center",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          className="col-md-3"
                          style={{ borderRight: "2px solid black" }}
                        >
                          <p>
                            <b>Chef</b>
                          </p>
                          <p>{chefOption.chef_name}</p>
                        </div>
                        <div className="col-md-4">
                          <p>
                            <b>Timing</b>
                          </p>
                          <p>Every {chefOption.timeslot} </p>
                        </div>
                        <div className="col-md-5 button-container">
                          <div style={{ position: "relative" }}>
                            <button
                              className="px-1 container-button"
                              style={{ width: "140px", fontSize: "0.7rem" }}
                              onClick={() => {
                                localStorage.setItem(
                                  "subscription_id",
                                  chefOption.subscription_id
                                );
                                handleButtonClick(option.chef, chefOption);
                              }}
                              disabled={option.status === "Inactive" || option.min_dates > option.chef.length}
                              >
                              Enter Order Details
                            </button>
                         
                          </div>

                          <button
                            className="mx-1 container-button"
                            style={{ width: "140px", fontSize: "0.7rem" }}
                            onClick={() => {
                              localStorage.setItem(
                                "subscription_id",
                                chefOption.subscription_id
                              );
                              navigate("/chef-list", {
                                state: {
                                  option: chefOption.subscription_id,
                                  type: "subscription_Id",
                                },
                              });
                            }}
                            disabled={option.status === "Inactive" || option.min_dates > option.chef.length}

                          >
                            Replace Chef
                          </button>
                          <button
                            className="px-1 container-button"
                            onClick={() => {
                              console.log(option.chef.length);

                              navigate("/subscription-message", {
                                state: {
                                  option: {
                                    chef_id: chefOption.subscribe_chef_id,
                                    customer_id:
                                      localStorage.getItem("logged_user_id"),
                                    name: chefOption.chef_name,
                                  },
                                },
                              });
                            }}
                            disabled={option.status === "Inactive" || option.min_dates > option.chef.length}
                          >
                            <img
                              src={chatBubble}
                              className="img-fluid mx-2"
                              alt=""
                            />
                          </button>
                        </div>
                      </div>
                    ))}
                    <div className="below_button">
                      {option.status === "Active" && option.max_dates > option.chef.length &&(
                        <div className="row text-center">
                          <div className="mb-3">
                            <button
                              className="p-2"
                              style={{
                                borderRadius: "20px",
                                border: "none",
                                backgroundColor: "#FF5E41",
                                color: "white",
                                fontSize: "1rem",
                                cursor: "pointer",
                              }}
                              onMouseEnter={() => setIsHovered(true)}
                              onMouseLeave={() => setIsHovered(false)}
                              onClick={() => handleNavigate()}
                            >
                              {isHovered ? (
                                <span
                                  className="px-3"
                                  style={{ fontSize: "0.8rem" }}
                                >
                                  + Select One More Day For The Week
                                </span>
                              ) : (
                                <span
                                  className="px-3"
                                  style={{ fontSize: "0.8rem" }}
                                >
                                  +
                                </span>
                              )}
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="right-card col-md-3 col-lg-3 col-sm-3 col-xs-3 d-flex flex-column">
                    {option.subscription.map(
                      (subscriptionOption, subscriptionIndex) => (
                        <div
                          key={subscriptionIndex}
                          className="row py-2 mx-1 flex-fill"
                          style={{
                            backgroundColor: "#FFEEEB",
                            borderRadius: "20px",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <div>
                            <p className="mb-2">
                              <b>Subscription Id</b>
                            </p>
                            <p className="my-1" style={{ color: "#FF5E41" }}>
                              {option.subscription_no}
                            </p>
                          </div>
                          <div
                            className={`col-md-${
                              option.chef.length === 1 ? 6 : 12
                            }  col-12`}
                            style={{
                              alignItems: "center",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div className="booking">
                              <p className="my-0" style={{ minHeight: "2rem" }}>
                                <b style={{ fontSize: "0.9rem" }}>
                                  Booking Cost
                                </b>
                              </p>
                              <p className="info-box px-3 py-2 my-0">
                                ${subscriptionOption.price ?? "00"}
                              </p>
                            </div>
                          </div>

                          <div
                            className={`col-md-${
                              option.chef.length === 1 ? 6 : 12
                            }  col-12`}
                            style={{
                              alignItems: "center",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div className="status">
                              <p className="my-0" style={{ minHeight: "2rem" }}>
                                <b style={{ fontSize: "0.9rem" }}>Status</b>
                              </p>
                              <p
                                className="info-box px-3 py-2 my-0"
                                style={{
                                  backgroundColor:
                                    subscriptionOption.status === "Active"
                                      ? "#98fb98"
                                      : "#ff5252",
                                  color: "white",
                                }}
                              >
                                {subscriptionOption.status}
                              </p>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                  <div>
                    {option.status === "Active" && option.min_dates > option.chef.length && (
                        <div
                         
                          style={{
                            color: "#FF5E41",
                            marginBottom: "5px",
                          }}
                        >
                         <span>{option.message} </span>
                          
                        
                        </div>
                      )}
                      </div>
                  <div
                    style={{
                      paddingTop: "5px",
                      display: option.status === "Active" ? " block" : "none",
                    }}
                  >
                    <p
                      className=" mx-2"
                      style={{
                        textWrap: "pretty",
                        textAlign: "center",
                        justifyContent: "center",
                        backgroundColor: " #ff5e41",
                        width: "200px",
                        padding: "7px 10px",
                        borderRadius: "28px",
                        color: "white",
                        cursor: "pointer",
                      }}
                      onClick={() => cancelConfirmationSubscription()}
                    >
                      Cancel Subscription
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <ClipLoader size={85} color="#FF5E41" />
            )}
          </div> */}

 {/* <FaRegMoneyBillAlt /> */}
          {/* 
        { <div className="new-design-card">
  <div className="row">
    {PlanList.map((option, index) => (
      <div className="col-md-4 col-sm-12" key={index}>
        <div
          className="border row border-1 m-1 py-2"
          style={{
            borderRadius: "20px",
            boxShadow: "0 0 0 rgba(0,0,0,0.2)", // Corrected boxShadow syntax
          }}
        >
          <div className="row">
            <div>
              <span
                className="badge text-wrap"
                style={{
                  borderRadius: "20px",
                 // Corrected to "marginLeft" from "marginleft"
                  color: "white",
                  backgroundColor:option.status==="Active"? "#4dff4d":"red", // Simplified the static backgroundColor
                }}
              >
                 {option.status}
              </span>
            
              <span
                className="badge text-wrap"
                style={{
                  borderRadius: "20px",
                  marginLeft: "10px", // Corrected to "marginLeft" from "marginleft"
                  color: "white",
                  backgroundColor:  `${option.color_code}`, // Simplified the static backgroundColor
                }}
              >
                   {option.plan_name}
              </span>
              
        
            </div>

            <div className="row my-2">
              <p className="pl-4 mb-0">Subscription ID: {option.subscription_no}</p>
            </div>
            {option.chef.map((chefData, ChefIndex) => (
  <div className="chef-new-card" key={ChefIndex}>
    <div className="row">
      <div className="col-md-2">
        <div
          className="d-flex justify-content-center align-items-center my-2"
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "50%",
          }}
        >
          <img
            src={
              chefData.booking_by_user_profile_image
                ? chefData.booking_by_user_profile_image
                : profile
            }
            style={{
              width: "50px",
              height: "50px",
              borderRadius: "50%",
            }}
            alt="Profile"
          />
        </div>
      </div>
      <div className="col-md-10 d-flex align-items-center">
        <h6 className="fw-bold">{chefData.chef_name}</h6>
      </div>
    </div>

    <div className="row">
      <div className="col-md-2">
        <div
          className="d-flex justify-content-center align-items-center my-2"
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            border: "1px solid #FF5E41",
            cursor: "pointer",
          }}
        >
          <img src={chatIcon} alt="Chat Icon" />
        </div>
      </div>
      <div className="col-md-10">
        <div className="row my-2">
          <div className="col-1">
            <p className="p-0 mb-0">
              <IoMdStopwatch />
            </p>
          </div>
          <div className="col-10">
            <p className="p-0 m-0" style={{ fontSize: "0.8rem" }}>
              Every {chefData.timeslot}
            </p>
          </div>
        </div>
        <div className="row my-2">
          <div className="col-1">
            <p className="p-0 mb-0">
              <MdOutlineAttachMoney />
            </p>
          </div>
          <div className="col-10 text-break">
            <p className="p-0 mb-0">{chefData.price}</p>
          </div>
        </div>
      </div>
    </div>

    <div className="button-container" style={{height:'2rem' }}>
      <div style={{ position: "relative" }}>
        <button
          className="px-1 container-button"
          style={{ width: "140px", fontSize: "0.7rem" }}
          onClick={() => {
            localStorage.setItem(
              "subscription_id",
              chefData.subscription_id
            );
            handleButtonClick(option.chef, chefData);
          }}
          disabled={option.status === "Inactive" || option.min_dates > option.chef.length}
        >
          Enter Order Details
        </button>
      </div>

      <button
        className="mx-1 container-button"
        style={{ width: "140px", fontSize: "0.7rem" }}
        onClick={() => {
          localStorage.setItem(
            "subscription_id",
            chefData.subscription_id
          );
          navigate("/chef-list", {
            state: {
              option: chefData.subscription_id,
              type: "subscription_Id",
            },
          });
        }}
        disabled={option.status === "Inactive" || option.min_dates > option.chef.length}
      >
        Replace Chef
      </button>

    </div>
    {ChefIndex < option.chef.length - 1 && <hr style={{ marginBottom: '5px' }} />}
  </div>
))}
    {option.status === "Active" && option.max_dates > option.chef.length && (
                        <div
                          className="below_button"
                          style={{
                            backgroundColor: "#FFEEEB",
                            // margin: "5px",
                            borderRadius: "5px",
                             display:'flex',
                             justifyContent:'center',
                            color: "#FF5E41",
                            fontSize: "0.7rem",
                            padding: "0.5rem",
                             marginTop: "10px",
                          }}
                        >
                          <div onClick={() => handleNavigate()}>
                            + Select One More Day For The Week
                          </div>
                        </div>
                      )}


          </div>
        
        </div>

        <div>
                    {option.status === "Active" && option.min_dates > option.chef.length && (
                        <div
                         
                          style={{
                            color: "#FF5E41",
                            marginBottom: "5px",
                          }}
                        >
                         <span>{option.message} </span>
                          
                        
                        </div>
                      )}
                      </div>

                    <div
                      style={{
                        paddingTop: "5px",
                        display: option.status === "Active" ? " block" : "none",
                      }}
                    >
                      <p
                        className=" mx-2"
                        style={{
                          textWrap: "pretty",
                          textAlign: "center",
                          justifyContent: "center",
                          backgroundColor: " #ff5e41",
                          width: "200px",
                          padding: "5px 5px",
                          borderRadius: "25px",
                          color: "white",
                          cursor: "pointer",
                          fontSize:'0.9rem'
                      
                        }}
                        onClick={() => cancelConfirmationSubscription()}
                      >
                        Cancel Subscription
                      </p>
                    </div>
      </div>

      
    ))}

  </div>
  
</div> } */}



            {/* <div className="mobile row text-center"
              style={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
            >
              {PlanList.length > 0 ? (
                PlanList.map((option, index) => (
                  <div className="row" key={index}>
                    <div
                      className="col-md-4 col-12 my-2 pt-1 px-1"
                      style={{
                        backgroundColor: "#f4f4f4",
                        borderRadius: "10px",
                      }}
                    >
                      <div>
                        <div
                          className="row mx-1 my-1"
                          style={{
                            backgroundColor: "#ffffff",
                            borderRadius: "10px",
                          }}
                        >
                          <div className="col-6">
                            <div
                              className="booking pe-2"
                              style={{
                                marginTop: "10px",
                              }}
                            >
                              <p
                                className="my-0"
                                style={{
                                  minHeight: "1.5rem",
                                  fontSize: "0.7rem",
                                }}
                              >
                                Booking Cost
                              </p>
                              <p
                                className="info-box px-3 py-1 mb-2"
                                style={{
                                  backgroundColor: "#FFEEEB",
                                  fontSize: "0.8rem",
                                }}
                              >
                                ${option.price}
                              </p>
                            </div>
                          </div>
                          <div className="col-6">
                            <div
                              className="booking"
                              style={{ marginTop: "10px" }}
                            >
                              <p
                                className="my-0"
                                style={{
                                  minHeight: "1.5rem",
                                  fontSize: "0.7rem",
                                }}
                              >
                                Status
                              </p>
                              <p
                                className="info-box px-3 py-1 my-0"
                                style={{
                                  backgroundColor: "#FFEEEB",
                                  fontSize: "0.8rem",
                                }}
                              >
                                {option.status}
                              </p>
                            </div>
                          </div>
                        </div>
  
                        {option.chef.map((chefOption, chefIndex) => (
                          <div
                            key={chefIndex}
                            className="row mx-1 my-1 mb-1"
                            style={{
                              backgroundColor: "#FFEEEB",
                              borderRadius: "10px",
                            }}
                          >
                            <div className="col-4 my-0 text-start">
                              <div
                                className="booking"
                                style={{
                                  borderRight: "2px solid #000000",
                                  marginTop: "10px",
                                }}
                              >
                                <p
                                  className="my-0"
                                  style={{
                                    minHeight: "2rem",
                                    fontSize: "0.8rem",
                                  }}
                                >
                                  Chef :-
                                </p>
                                <p
                                  className="my-0"
                                  style={{
                                    minHeight: "2rem",
                                    fontSize: "0.8rem",
                                  }}
                                >
                                  Timing :-
                                </p>
                              </div>
                            </div>
                            <div className="col-8 my-0 text-start">
                              <div
                                className="booking"
                                style={{ marginTop: "10px" }}
                              >
                                <p
                                  className="my-0"
                                  style={{
                                    minHeight: "2rem",
                                    fontSize: "13px",
                                  }}
                                >
                                  {chefOption.chef_name}
                                </p>
                                <p
                                  className="my-0"
                                  style={{
                                    minHeight: "2rem",
                                    fontSize: "13px",
                                  }}
                                >
                                  Every {chefOption.timeslot}
                                </p>
                              </div>
                            </div>
                            <div className="my-3" style={{ display: "flex" }}>
                              <button
                                className="px-1 py-0 container-button-mobile"
                                onClick={() => {
                                  localStorage.setItem(
                                    "subscription_id",
                                    chefOption.subscription_id
                                  );
                                  handleButtonClick(option.chef, chefOption);
                                }}
                                disabled={
                                  option.status === "Inactive" ||
                                  option.min_dates > option.chef.length
                                }
                              >
                                <span style={{ fontSize: "9px" }}>
                                  Enter Order Details
                                </span>
                              </button>
  
                              <button
                                className="mx-1 container-button-mobile"
                                onClick={() => {
                                  localStorage.setItem(
                                    "subscription_id",
                                    chefOption.subscription_id
                                  );
                                  navigate("/chef-list", {
                                    state: {
                                      option: chefOption.subscription_id,
                                      type: "subscription_Id",
                                    },
                                  });
                                }}
                                disabled={
                                  option.status === "Inactive" ||
                                  option.min_dates > option.chef.length
                                }
                              >
                                <span style={{ fontSize: "9px" }}>
                                  Replace Chef
                                </span>
                              </button>
                              <button
                                className="px-1 container-button-mobile"
                                onClick={() => {
  
  
                                  navigate("/chef/message-view", {
                                    state: {
                                      data: {
                                     
                                      },
                                    },
                                  });
  
  
                                  navigate("/subscription-message", {
                                    state: {
                                      option: {
                                        chef_id: chefOption.subscribe_chef_id,
                                        customer_id:
                                          localStorage.getItem("logged_user_id"),
                                        name: chefOption.chef_name,
                                        subscription_status: chefOption.subscription_status,
  
                                      },
                                    },
                                  });
                                }}
                                // disabled={
                                //   option.status === "Inactive" ||
                                //   option.min_dates > option.chef.length
                                // }
                              >
                                <img
                                  style={{ width: "15px", height: "15px" }}
                                  src={chatBubble}
                                  className="img-fluid mx-2"
                                  alt=""
                                />
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
  
                      {option.status === "Active" &&
                        option.max_dates > option.chef.length && (
                          <div
                            className="below_button"
                            style={{
                              backgroundColor: "#FFEEEB",
                              margin: "5px",
                              borderRadius: "5px",
                              color: "#FF5E41",
                              fontSize: "0.7rem",
                              padding: "0.5rem",
                              marginBottom: "10px",
                            }}
                          >
                            <div onClick={() => handleNavigate()}>
                              + Select One More Day For The Week
                            </div>
                          </div>
                        )}
                    </div>
  
                    <div>
                      {option.status === "Active" &&
                        option.min_dates > option.chef.length && (
                          <div
                            style={{
                              color: "#FF5E41",
                              marginBottom: "5px",
                            }}
                          >
                            <span>{option.message} </span>
                          </div>
                        )}
                    </div>
  
                    <div
                      style={{
                        paddingTop: "5px",
                        display: option.status === "Active" ? " block" : "none",
                      }}
                    >
                      <p
                        className=" mx-2"
                        style={{
                          textWrap: "pretty",
                          textAlign: "center",
                          justifyContent: "center",
                          backgroundColor: " #ff5e41",
                          width: "200px",
                          padding: "5px 5px",
                          borderRadius: "25px",
                          color: "white",
                          cursor: "pointer",
                          fontSize: "0.9rem",
                        }}
                        onClick={() => cancelConfirmationSubscription()}
                      >
                        Cancel Subscription
                      </p>
                    </div>
                  </div>
                ))
              ) : (
                <div className='text-center'>
                <ClipLoader size={85} color="#FF5E41" />
                </div>
              )}
            </div> */}
          {/* /////////// */}