async function getLatLong(addressDetails = {}) {
    const { postal_code, city, country, line1, line2, state } = addressDetails;
    const apiKey = process.env.REACT_APP_GEOCODING_API_KEY;

    let address = '';

    if (postal_code || city || state || country || line1 || line2) {
        let addressComponents = [];

        if (line1) { // Add line 1 if present
            addressComponents.push(line1);
        }

        if (line2) { // Add line 2 if present
            addressComponents.push(line2);
        }

        if (city || state || country) { // Add city, state, and country if at least one of them is present
            addressComponents.push([city, state, country].filter(Boolean).join(', '));
        }

        if (postal_code) {  // Add postal code if present
            addressComponents.push(postal_code);

            console.log(postal_code);
            
        }

        // Join all address components into a single string
        address = addressComponents.join(', ');
    } else {
        // Handle missing or incomplete address details
        console.error('Missing or incomplete address details.');
        return null;
    }

    // console.log('Full LatLong Address: ', address);

    // Construct the URL for the Geocoding API request
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${apiKey}`;

    try {
        // Fetch data from the Geocoding API
        const response = await fetch(apiUrl);
        const data = await response.json();

        // Check if the API request was successful
        if (data.status === 'OK') {
            // Extract latitude and longitude from the API response
            const location = data.results[0].geometry.location;
            const latitude = location.lat;
            const longitude = location.lng;

            // Return latitude and longitude
            return { latitude, longitude };
        } else {
            // Handle API error
            console.error('Geocoding API error:', data.error_message);
            return null;
        }
    } catch (error) {
        // Handle fetch error
        console.error('Error fetching data from Geocoding API:', error);
        return null;
    }
}

export default getLatLong;