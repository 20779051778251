import React, { useState, useEffect } from "react";
import Spinner from "./BePatient";
import { useNavigate } from "react-router-dom";
import LoggedNav from "../components/LoggedNav";
import ContactUsPop from "../components/ContactUsPop";
import OrderItemChef from "../components/OrderItemChef";
import SubcriptionList from "../components/SubcriptionList";
import { isMobile } from "react-device-detect";

import { Link } from "react-router-dom";

function SubscriptionListChef(props) {

  const [activeButton, setActiveButton] = useState("orders"); // State to track active button
  const [orders, setOrders] = useState([]);
  const [subcription, setsubcription] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const updateOrders = async () => {
    let data  = await fetch(
      `${process.env.REACT_APP_BASE_URL}book/chef_booking_history`,
      {
        method: "POST",
         headers: {
          "Authorization": "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
         body: JSON.stringify({
          "email":`${localStorage.getItem("owner")}`,
          "user_id":`${localStorage.getItem("chef_id")}`,                   
         })
                                      
      }
    );
    let parsedData = await data.json();
    console.log(parsedData);
    setOrders(parsedData.data);
    setLoading(false);
  console.log({
            "email":`${localStorage.getItem("owner")}`,
            "user_id":`${localStorage.getItem("chef_id")}`,                   
           });
  };
  const getSubcriptionData = async () => {
    let data  = await fetch(
      `${process.env.REACT_APP_BASE_URL}subscription/fetch_subscription_for_chef`,
      {
        method: "POST",
   headers: {
          "Authorization": "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
         body: JSON.stringify({
          "chef_id":`${localStorage.getItem("chef_id")}`,                   
         })
                                      
      }
    );
    let parsedData = await data.json();
    console.log(parsedData);
    setsubcription(parsedData.data);
    setLoading(false);
  console.log({
            "email":`${localStorage.getItem("owner")}`,
            "user_id":`${localStorage.getItem("chef_id")}`,                   
           });
  };
  const getStatusList = async () => {
    let data = await fetch(
      `${process.env.REACT_APP_BASE_URL}status/all_status`
    );
    let parsedData = await data.json();
    for (let index = 0; index < parsedData.data.length; index++) {
      const element = parsedData.data[index];
      localStorage.setItem(element.name, element.color)
    }
  };
  const routeTosignIn = async () => {
    if((localStorage.getItem("role"))){
      console.log('display Play List');
    }else{
      navigate("/sign-in");
    }
  };
  

  let role = localStorage.getItem("role");
  let token = localStorage.getItem("token");
  useEffect(() => {

    var title = `Order History | CHEF | CHEF REPUBLIC`;
    var desc = "Review Orders you have Meal Prepped for customers of Chef Republic"
    const titleTag = document.querySelector('title');
    titleTag.innerText = title;
    const metaTitle = document.querySelector("meta[name='title']");
    metaTitle.setAttribute('content',title)
    const metaDescription = document.querySelector("meta[name='description']");
    metaDescription.setAttribute('content',desc)
    const metaTitleOG = document.querySelector("meta[property='og:title']");
    metaTitleOG.setAttribute('content',title)
    const metaDescriptionOG = document.querySelector("meta[property='og:description']");
    metaDescriptionOG.setAttribute('content',desc)
    const metaTitleTwitter = document.querySelector("meta[property='twitter:title']");
    metaTitleTwitter.setAttribute('content',title)
    const metaDescriptionTwitter = document.querySelector("meta[property='twitter:description']");
    metaDescriptionTwitter.setAttribute('content',desc)

    routeTosignIn();
    localStorage.removeItem("from_msg")
    getStatusList();
    updateOrders();
    getSubcriptionData();
    // eslint-disable-next-line
  }, [activeButton]);
  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <LoggedNav customer={props.customer} main={true} />
          <div className="container my-5">
            <div className="text-center mb-4">
              <h1 className="fw-bold text-center fs-2">
                {"Subscription History"}
              </h1>
              <div className="text-center mt-3">
                <Link
                  className={`${"btn-white"} mx-2`}
                  to="/chef/order-list"
                  style={{
                    width: isMobile ? "165px" : "200px",
                    display: "inline-block",
                    textAlign: "center",
                  }}
                >
                  Order
                </Link>

                <Link
                  className={`${ "order_details"} mx-2`}
                  to="/subscription-list"

                  style={{
                    width: isMobile ? "165px" : "200px",
                    display: "inline-block",
                    textAlign: "center",
                  }}
                >
                  Subscription
                </Link>
              </div>
            </div>

            <div className="row">
              {subcription && subcription.length > 0 ? (
                subcription.map((content, index) => (
                  <SubcriptionList key={index} content={content} />
                ))
              ) : (
                <h4 className="py-3 text-center fs-6">
                  You have no subscriptions yet. Stay tuned!
                </h4>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default SubscriptionListChef;