import React, { useEffect } from "react";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import thiredBlog from "../images/ThiredBlog.jpeg"

function ThiredBlog(props) {
  useEffect(() => {
    const title = "HOME | CHEF REPUBLIC";
    const desc =
      "On Demand Home Chef Service, for Personalized Meal Prep at an affordable rate. Book a home chef to come to your home with fresh groceries and cook/meal prep healthy homemade meals for you and your family for up to 20 servings, catered to your preference and taste.";

    document.title = title;

    const metaTitle = document.querySelector("meta[name='title']");
    const metaDescription = document.querySelector("meta[name='description']");
    const metaTitleOG = document.querySelector("meta[property='og:title']");
    const metaDescriptionOG = document.querySelector(
      "meta[property='og:description']"
    );
    const metaTitleTwitter = document.querySelector(
      "meta[property='twitter:title']"
    );
    const metaDescriptionTwitter = document.querySelector(
      "meta[property='twitter:description']"
    );

    if (metaTitle) metaTitle.setAttribute("content", title);
    if (metaDescription) metaDescription.setAttribute("content", desc);
    if (metaTitleOG) metaTitleOG.setAttribute("content", title);
    if (metaDescriptionOG) metaDescriptionOG.setAttribute("content", desc);
    if (metaTitleTwitter) metaTitleTwitter.setAttribute("content", title);
    if (metaDescriptionTwitter)
      metaDescriptionTwitter.setAttribute("content", desc);
  }, []);

  return (
    <>
      <Nav customer={props.customer} home={true} />

      <div className="container">
        <p className="fw-bold mt-4" style={{ fontSize: "2rem" }}>
        How to Find and Hire a Personal Chef for Your Home
        </p>
        <div
          id="firstBlogs"
          style={{
            background: `url(${thiredBlog})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        ></div>

        <p className="mt-3">
        If you're tired of meal planning, grocery shopping, and cooking daily, it's time to hire a personal chef. Many services today offer professional chefs who come to your home to prepare customized menus tailored to your taste and dietary preferences.
        </p>
        <p>
          {" "}
          For those seeking an easy solution, on-demand home chef services like Chef Republic allow you to hire a chef for any occasion, whether it’s a casual family meal or a special event. From private chefs to professional restaurant chefs, these services cater to your needs, providing home chefs who handle everything from groceries to cleanup. Simply log in to your preferred platform, browse chef profiles, and book your meal.

        </p>
     
        <p>
        Wondering about the cost? How much does it cost to hire a private chef? Prices vary, but many services offer fixed-price packages, ensuring you get the best value for your money. Plus, with the option to choose from personal cooks near me or chefs for hire, finding the right chef has never been easier.

        </p>
        <p>
        Now, forget about the stress of cooking and enjoy the private cheffing right at home!
        </p>

       
      </div>

      <Footer customer={props.customer} home={true} />
    </>
  );
}

export default ThiredBlog;