import React, { useState, useEffect } from "react";
import Spinner from "./BePatient";
import { useNavigate, Link } from "react-router-dom";
import LoggedNav from "../components/LoggedNav";
import ContactUsPop from "../components/ContactUsPop";
import OrderItemChef from "../components/OrderItemChef";
import { isMobile } from "react-device-detect";

function OrderListChef(props) {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();


  const routeTosignIn = async () => {
    if((localStorage.getItem("role"))){
      console.log('display Play List');
    }else{
      navigate("/sign-in");
    }
  };

  const updateOrders = async () => {
    try {
      let response = await fetch(
        `${process.env.REACT_APP_BASE_URL}book/chef_booking_history`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: `${localStorage.getItem("owner")}`,
            user_id: `${localStorage.getItem("chef_id")}`,
          }),
        }
      );

      let parsedData = await response.json();
      console.log(parsedData);
      setOrders(parsedData.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching orders:", error);
      setLoading(false);
    }
  };



  const getStatusList = async () => {
    try {
      let response = await fetch(
        `${process.env.REACT_APP_BASE_URL}status/all_status`
      );
      let parsedData = await response.json();
      parsedData.data.forEach((element) => {
        localStorage.setItem(element.name, element.color);
      });
    } catch (error) {
      console.error("Error fetching status list:", error);
    }
  };

  useEffect(() => {
    // Update meta tags
    const title = `Order History | CHEF | CHEF REPUBLIC`;
    const desc = "Review Orders you have Meal Prepped for customers of Chef Republic";
    document.title = title;
    document.querySelector("meta[name='title']").setAttribute("content", title);
    document
      .querySelector("meta[name='description']")
      .setAttribute("content", desc);
    document
      .querySelector("meta[property='og:title']")
      .setAttribute("content", title);
    document
      .querySelector("meta[property='og:description']")
      .setAttribute("content", desc);
    document
      .querySelector("meta[property='twitter:title']")
      .setAttribute("content", title);
    document
      .querySelector("meta[property='twitter:description']")
      .setAttribute("content", desc);

    localStorage.removeItem("from_msg");
    
    routeTosignIn();

    getStatusList();
    updateOrders();


    
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <LoggedNav customer={props.customer} main={true} />
          <div className="container my-5">
            <div className="text-center mb-4">
              <h1 className="fw-bold text-center fs-2">{"Order History"}</h1>
              <div className="text-center mt-3">
                <Link
                  className={`order_details mx-2`}
                  to="/chef/order-list"
                  style={{
                    width: isMobile ? "165px" : "200px",
                    display: "inline-block",
                    textAlign: "center",
                  }}
                >
                  Order
                </Link>
                <Link
                  className={`btn-white mx-2`}
                  to="/subscription-list"
                  style={{
                    width: isMobile ? "165px" : "200px",
                    display: "inline-block",
                    textAlign: "center",
                  }}
                >
                  Subscription
                </Link>
              </div>
            </div>
            {/* <div className="row">
              {orders.length > 0 ? ( orders.map((content, index) => (<OrderItemChef key={index} content={content} />)) ) :
               (  <h4 className="py-3 text-center fs-6">  You have No Orders yet. Stay Tuned</h4>
              )}
            </div> */}

            <div className="row py-4">
              {orders && (orders.length>0) ? orders.map((content, index) => {
                
                return <OrderItemChef key={index} data={content} />;
              }) : <h4 className="py-3 text-center fs-6">You have No Orders yet. Stay Tuned</h4>}
            </div>
          </div>
          <ContactUsPop />
        </>
      )}
    </>
  );
}

export default OrderListChef;
