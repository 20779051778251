import React, { useEffect, useState } from "react";
import DietItem from "./DietOfferItem";
import getLatLong from "../helpers/getLatLong";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

function DietOffer(props) {
    const [diets, setDiets] = useState([]);
    const navigate = useNavigate();

    const updateDiets = async () => {
        let data = await fetch(`${process.env.REACT_APP_BASE_URL}general/categories`);
        let parsedData = await data.json();
        setDiets(parsedData.data);
    };
    useEffect(() => {
        updateDiets();
        // eslint-disable-next-line
    }, []);

    const [filterAddress, setFilterAddress] = useState({
        postal_code: '', line1: '', country: 'US', latitude: '', longitude: ''
    });

    const [addressLoading, setAddressLoading] = useState(false);

    const handleAddressSubmit = async (e) => {
        e.preventDefault();
debugger



    // Check if 'user' (or any other key you set in localStorage) is present
const isAuthenticated = localStorage.getItem("role") ;

if (isAuthenticated) {
    if (filterAddress.postal_code.length !== 5) {
        toast.error('Please enter a valid Zip code');
        return;
    }

    setAddressLoading(true);
    // console.log(filterAddress);

    // Fetch latitude and longitude for given address
    try {
        const coordinates = await getLatLong(filterAddress);
        if (coordinates) {
            setFilterAddress({ ...filterAddress, latitude: coordinates.latitude, longitude: coordinates.longitude });
            navigate('/chef-list', { state: { ...filterAddress, latitude: coordinates.latitude, longitude: coordinates.longitude } });
        } else {
            console.log('Failed to retrieve coordinates.');
            toast.error('Please enter a valid proper address');
            return;
        }
    } catch (error) {
        setAddressLoading(false);
        console.error('Error:', error);
        toast.error('Failed to fetch precise location, try again later.');
        return;
    } finally {
        setAddressLoading(false);
    }

} else {
// If not authenticated, navigate to the login page
navigate("/sign-in");

}





    

    };

    return (
        <div id="diet-offer">


            <div className="container-fluid d-flex flex-column justify-content-center py-5" style={{ minHeight: '40vh', backgroundColor: '#FFDFD9' }}>
                <h3 className="text-center mb-5">Find Chefs in your area </h3>

                <form onSubmit={handleAddressSubmit} className="row g-3 d-flex justify-content-center mb-5">
                    <div className="col-11 col-md-4 text-center text-md-start">
                        <input className='form-control shadow-none' id='home-page-inputs' placeholder="Address *"
                            type="text" name="line1"
                            value={filterAddress.line1}
                            required
                            onChange={(e) => setFilterAddress({ ...filterAddress, line1: e.target.value })}
                        />
                        <p className='mt-2 mb-0 ms-md-3' style={{ fontSize: '0.7rem' }}>A precise address helps locate Nearby Chefs more accurately.</p>
                    </div>

                    <div className="col-11 col-md-auto">
                        <input className='form-control shadow-none' id='home-page-inputs' autoFocus placeholder="Zip Code *"
                            type="number" name="postal_code" maxLength={5} minLength={5} required
                            value={filterAddress.postal_code}
                            onChange={(e) => setFilterAddress({ ...filterAddress, postal_code: e.target.value })}
                        />
                    </div>

                    <div className="col-auto">
                        <button className="btn-orange col-auto" type='submit' disabled={addressLoading}>{addressLoading && <span className="spinner-border spinner-border-sm me-2" ></span>} Show Nearby Chefs</button>
                    </div>
                </form>
            </div>

            <div className="container my-5" >
                <h3 className="text-center">Diet & Cuisines we offer</h3>
                <div className="py-5 row row-cols-md-5 row-cols-sm-2 d-flex justify-content-center">
                    {
                        diets.map((element, index) => {
                            return <DietItem key={index} data={element} />;
                        })
                    }
                </div>
            </div>
        </div>
    );
}

export default DietOffer;
