import React from "react";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import { Link } from 'react-router-dom';
import firstBlogImage from "../images/firstblogimage.jpg"
import secondBlog from "../images/secondBlog.png";
import thiredBlog from "../images/ThiredBlog.jpeg"
import fourthBlog from "../images/fourthBlog.jpeg"
import FifthBlog from "../images/fifthBlog.png";
import SixthBlog  from "../images/sixthBlog.png";
import SeventhBlog from "../images/seventhBlog.png";



function Blogs(props) {
  React.useEffect(() => {
    var title = "HOME | CHEF REPUBLIC"
    var desc = "On Demand Home Chef Service,for Personalized Meal Prep at an affordable rate. Book a home chef to come to your home with fresh groceries and cook/meal prep healthy home made meals for you and your family for Upto 20 servings, catered to your preference and taste."
    const titleTag = document.querySelector('title');
    titleTag.innerText = title;
    const metaTitle = document.querySelector("meta[name='title']");
    metaTitle.setAttribute('content',title)
    const metaDescription = document.querySelector("meta[name='description']");
    metaDescription.setAttribute('content',desc)
    const metaTitleOG = document.querySelector("meta[property='og:title']");
    metaTitleOG.setAttribute('content',title)
    const metaDescriptionOG = document.querySelector("meta[property='og:description']");
    metaDescriptionOG.setAttribute('content',desc)
    const metaTitleTwitter = document.querySelector("meta[property='twitter:title']");
    metaTitleTwitter.setAttribute('content',title)
    const metaDescriptionTwitter = document.querySelector("meta[property='twitter:description']");
    metaDescriptionTwitter.setAttribute('content',desc)
  }, []);
  return (
    <>
      <Nav customer={props.customer} home={true} />
      <div className="row">
        <h1
          className="text-center fw-bolder pt-5 mb-5"
          style={{
            fontFamily: "'Roboto', sans-serif",
            fontSize: "45px",
            lineHeight: "35px",
          }}
        >
          BLOGS
        </h1>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="my-3">
              <Link
                to={`/blogs/on-demand-home-chefs-are-a-game-changer-for-Busy-families`}
                style={{ color: "#000000", textDecoration: "none" }}
              >
                <div className="row">
                  <div className="col-md-3 col-12">
                    <img src={firstBlogImage} width={"100%"} />
                  </div>
                  <div className="col-md-9 col-12">
                    <h2>
                      <a style={{ textDecoration: "none" }}>
                        {
                          "On-Demand Home Chefs Are a Game-Changer for Busy Families"
                        }
                      </a>
                    </h2>
                    <p className="content">
                      {" "}
                      Finding the time to cook healthy and tasty meals can be a
                      real challenge in our busy lives. With work, school, and
                      other activities, cooking often feels like one more chore.
                      But on-demand home chefs are changing the game for busy
                      families. They make mealtimes easy and stress-free. Let’s
                      dive into why hiring a home chef can be a great decision
                      for your family.
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="my-3">
              <Link
                to={`/blogs/home-dining-experience-with-chef-republic's-on-demand-chef-service`}
                style={{ color: "#000000", textDecoration: "none" }}
              >
                <div className="row">
                  <div className="col-md-3 col-12">
                    <img src={secondBlog} width={"100%"} />
                  </div>
                  <div className="col-md-9 col-12">
                    <h2>
                      <a style={{ textDecoration: "none" }}>
                        {
                          "Home Dining Experience with Chef Republic's On-Demand Chef Service"
                        }
                      </a>
                    </h2>
                    <p className="content">
                      {" "}
                      Do you want the same old home-cooked meals or need help
                      finding the time to prepare delicious dishes? Say goodbye
                      to kitchen stress and hello to gourmet dining with Chef
                      Republic’s on-demand chef service! Imagine enjoying
                      restaurant-quality meals right in the comfort of your own
                      home. Chef Republic brings professional chefs to your
                      kitchen, turning your dining experience into a luxurious
                      event. Whether you're hosting a family dinner or birthday
                      party or just want to indulge in a special meal, their
                      chefs cater to your needs.
                    </p>
                    <p></p>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="my-3">
              <Link
                to={`/blogs/how-to-find-and-hire-a-personal-chef-for-your-home`}
                style={{ color: "#000000", textDecoration: "none" }}
              >
                <div className="row">
                  <div className="col-md-3 col-12">
                    <img src={thiredBlog} width={"100%"} />
                  </div>
                  <div className="col-md-9 col-12">
                    <h2>
                      <a style={{ textDecoration: "none" }}>
                        {"How to Find and Hire a Personal Chef for Your Home"}
                      </a>
                    </h2>
                    <p className="content">
                      {" "}
                      If you're tired of meal planning, grocery shopping, and
                      cooking daily, it's time to hire a personal chef. Many
                      services today offer professional chefs who come to your
                      home to prepare customized menus tailored to your taste
                      and dietary preferences.
                    </p>
                    <p></p>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="my-3">
              <Link
                to={`/blogs/where-to-hire-a-chef-for-your-house-party`}
                style={{ color: "#000000", textDecoration: "none" }}
              >
                <div className="row">
                  <div className="col-md-3 col-12">
                    <img src={fourthBlog} width={"100%"} />
                  </div>
                  <div className="col-md-9 col-12">
                    <h2>
                      <a style={{ textDecoration: "none" }}>
                        {"Where to Hire a Chef for Your House Party?"}
                      </a>
                    </h2>
                    <p className="content">
                      {" "}
                      If you're planning a house party and want a stress-free
                      dining experience, Chef Republic’s on-demand home chef
                      service could be the perfect solution. Recently, a host
                      used their service and found it a game-changer. They
                      explored a range of chef profiles and customized menus to
                      find the ideal match for their event.
                    </p>
                    <p></p>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="my-3">
              <Link
                to={`/blogs/health_meets_gourmet:_how_private_chefs_customize_meals_for_wellness_goals`}
                style={{ color: "#000000", textDecoration: "none" }}
              >
                <div className="row">
                  <div className="col-md-3 col-12">
                    <img src={FifthBlog} width={"100%"} />
                  </div>
                  <div className="col-md-9 col-12">
                    <h2>
                      <a style={{ textDecoration: "none" }}>
                        {
                          "Health Meets Gourmet: How Private Chefs Customize Meals for Wellness Goals"
                        }
                      </a>
                    </h2>
                    <p className="content">
                      {" "}
                      People who care about their health are changing what it
                      means to eat well these days. For many, finding the right
                      balance between taste and health goals has become very
                      important. This is especially true for busy people who
                      want their food choices to support their health goals.
                      Private chefs make this possible by letting clients enjoy
                      gourmet meals made just for them at home while focused on
                      their own specific health needs.
                    </p>
                    <p></p>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="my-3">
              <Link
                to={`/blogs/mastering_global_flavors_at_home_exploring_world_cuisines_with_a_private_chef`}
                style={{ color: "#000000", textDecoration: "none" }}
              >
                <div className="row">
                  <div className="col-md-3 col-12">
                    <img src={SixthBlog} width={"100%"} />
                  </div>
                  <div className="col-md-9 col-12">
                    <h2>
                      <a style={{ textDecoration: "none" }}>
                        {
                          "Mastering Global Flavors at Home: Exploring World Cuisines with a Private Chef"
                        }
                      </a>
                    </h2>
                    <p className="content">
                      {" "}
                      Today's world has a lot of different kinds of food, and
                      it's easier and more fun than ever to try them all from
                      the comfort of your own home. There is a huge variety of
                      tastes and textures in world foods, from the strong spices
                      of Indian curries made by a skilled chef of India to the
                      fresh flavors of Mediterranean salads. Each bite can take
                      you to a different part of the world. You can enjoy these
                      flavors from around the world in ways you never thought
                      possible with the help of a hired chef, all without
                      leaving your home.
                    </p>
                    <p></p>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="my-3">
              <Link
                to={`/blogs/time_is_money_how_hiring_a_private_chef_can_be_a_smart_investment_for_busy_professionals`}
                style={{ color: "#000000", textDecoration: "none" }}
              >
                <div className="row">
                  <div className="col-md-3 col-12">
                    <img src={SeventhBlog} width={"100%"} />
                  </div>
                  <div className="col-md-9 col-12">
                    <h2>
                      <a style={{ textDecoration: "none" }}>
                        {
                          "Time is Money: How Hiring a Private Chef Can Be a Smart Investment for Busy Professionals"
                        }
                      </a>
                    </h2>
                    <p className="content">
                      {" "}
                      One of our most valuable resources in the fast-paced environment of today is time. Juggling job, family, and social obligations for time-pressed professionals usually leaves little space for planning and cooking great, mouthwatering meals. The fix is recruiting a hired chef. Although it may initially seem like a luxury, a private chef can be a strategic investment that saves you time, increases your output, and improves your way of life. For individuals who appreciate their time and well-being, hiring a chef's services can help to simplify life.
                    </p>
                    <p></p>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer customer={props.customer} home={true} />
    </>
  );
}

export default Blogs;
